import { FC, Fragment } from 'react';
import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import Stepper from '@mui/material/Stepper';
import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { isLight } from '../common/helpers';
import { KnownBreakpoints, THEME_BREAKPOINTS } from '../common/constants';

const styles = {
    stepper: {
        width: 'fit-content',
    },
    step: {
        padding: 0,
    },
    stepLabel: (theme: Theme, current: boolean, notCompleted: boolean) => ({
        width: current ? '26px' : '20px',
        height: current ? '26px' : '20px',
        borderRadius: '50%',
        backgroundColor: current
            ? isLight(theme) ? theme.palette.common.white : 'transparent'
            : notCompleted
                ? theme.palette.divider
                : theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        border: current ? `2px solid ${ theme.palette.primary.main }` : 'none',
        alignItems: 'center',
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            border: current
                ? `2px solid ${ theme.palette.common.white }`
                : 'none',
            backgroundColor: current
                ? 'transparent'
                : notCompleted
                    ? theme.palette.info.light
                    : theme.palette.common.white,
        },
    }),
    stepNumber: (theme: Theme, current: boolean, notCompleted: boolean) => ({
        fontWeight: current ? 900 : 600,
        fontSize: current ? '15px' : '12px',
        lineHeight: current ? '17.58px' : '14.06px',
        color: current
            ? isLight(theme)
                ? theme.palette.primary.main
                : theme.palette.text.primary
            : isLight(theme)
                ? theme.palette.common.white
                : notCompleted
                    ? theme.palette.text.primary
                    : theme.palette.secondary.main,
        [theme.breakpoints.down(THEME_BREAKPOINTS[KnownBreakpoints.tablet])]: {
            color: current
                ? theme.palette.common.white
                : theme.palette.primary.main,
        },
    }),
    stepsConnector: (theme: Theme, completed: boolean) => ({
        '& > span' : {
            width: '20px',
            border: `solid 1px ${ completed
                ? theme.palette.primary.main
                : theme.palette.divider }`,
            [theme.breakpoints.down(
                THEME_BREAKPOINTS[KnownBreakpoints.tablet],
            )]: {
                border: `solid 1px ${ completed
                    ? theme.palette.common.white
                    : theme.palette.info.light }`,
            },
        },
    }),
};

export interface StepProps {
    step: {
        current: number;
        number: number;
    }
}

const StepComponent: FC<StepProps> = ({ step: { number, current }}) => {
    const theme = useTheme<Theme>();

    return <Stepper sx={ styles.stepper }>
        { Array.from({ length: number }, (_, index) => (
            <Fragment key={ index }>
                <Step sx={ styles.step } key={ index }>
                    <Box sx={ styles.stepLabel(
                        theme,
                        current === index + 1,
                        index + 1 > current && index + 1 <= number,
                    )}>
                        <Typography sx={ styles.stepNumber(
                            theme,
                            current === index + 1,
                            index + 1 > current && index + 1 <= number,
                        )}>
                            { index + 1 }
                        </Typography>
                    </Box>
                </Step>
                { index + 1 !== number &&
                    <StepConnector sx={ styles.stepsConnector(
                        theme,
                        index + 1 < current,
                    )}/>
                }
            </Fragment>
        ))}
    </Stepper>;
};

export default StepComponent;
