import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { Theme } from '@mui/material/styles';
import { isLight } from '../../common/helpers';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../../common/constants';
import Paper from '@mui/material/Paper';

const styles = {
    desktopPaper: {
        width: '1130px',
        height: '720px',
    },
    desktopContent: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    desktopLeftContent: (theme: Theme) => ( {
        width: '70%',
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.main
        ,
        '@media print': {
            width: '100%',
        },
    }),
    desktopRightContent: (theme: Theme) => ({
        width: '30%',
        backgroundColor: isLight(theme)
            ? '#D5ECF4'
            : theme.palette.info.main
        ,
        '@media print': {
            display: 'none',
        },
    }),
    laptopMainPaper: {
        maxWidth: '1130px',
        backgroundColor: 'transparent',
        backgroundImage: 'none',
    },
    laptopPaperTop: (theme: Theme) => ({
        height: '800px',
        marginBottom: '20px',
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.main
        ,
    }),
    laptopPaperBottom: (theme: Theme) => ({
        backgroundColor: isLight(theme)
            ? '#D5ECF4'
            : theme.palette.info.main
        ,
        padding: '40px',
    }),
};

export interface DialogDesktopProps {
    leftContent: JSX.Element;
    rightContent: JSX.Element;
    dialogProps: DialogProps;
}

const DialogDesktop: FunctionComponent<DialogDesktopProps> = props => {
    const { leftContent, rightContent, dialogProps } = props;

    return <Dialog
        { ...dialogProps }
        fullScreen={ false }
        fullWidth={ true }
        PaperProps={{ sx: styles.desktopPaper }}
    >

        <Box sx={ styles.desktopContent }>
            <Box sx={ styles.desktopLeftContent }>
                { leftContent }
            </Box>
            <Box sx={ styles.desktopRightContent }>
                { rightContent }
            </Box>
        </Box>
    </Dialog>;
};

const DialogLaptop: FunctionComponent<DialogDesktopProps> = props => {
    const { leftContent, rightContent, dialogProps } = props;

    return <Dialog
        { ...dialogProps }
        fullScreen={ false }
        fullWidth={ true }
        PaperProps={{ sx: styles.laptopMainPaper }}
        scroll={ 'body' }
    >
        <Paper sx={ styles.laptopPaperTop }>
            { leftContent }
        </Paper>
        <Paper sx={ styles.laptopPaperBottom }>
            { rightContent }
        </Paper>
    </Dialog>;
};

const DialogDesktopLayout: React.FunctionComponent<
    DialogDesktopProps
> = props => {
    const theme = useTheme<Theme>();
    const isLaptop = useMediaQuery(
        theme.breakpoints.between(
            KnownBreakpoints.tablet,
            KnownBreakpoints.laptop,
        ),
    );

    return !isLaptop
        ? <DialogDesktop { ...props }/>
        : <DialogLaptop { ...props }/>
    ;
};

export default DialogDesktopLayout;
