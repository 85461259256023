import React, { FunctionComponent } from 'react';
import DialogDesktopLayout, {
    DialogDesktopProps,
} from '../layouts/DialogLayout/Desktop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { isLight } from '../common/helpers';
import { KnownBreakpoints } from '../common/constants';
import StepComponent from './StepComponent';

const styles = {
    container: (theme: Theme, padding?: string) => ({
        padding: padding ? padding : '33px 115px 38px 45px',
        height: '100%',
        [theme.breakpoints.between(
            KnownBreakpoints.tablet,
            KnownBreakpoints.laptop,
        )]: {
            padding: '33px 45px 38px 45px',
        },
    }),
    title: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '28.13px',
        color: theme.palette.text.primary,
        paddingBottom: '10px',
    }),
    step: (theme: Theme) => ({
        ...theme.typography.h6,
        lineHeight: '16.41px',
        color: theme.palette.primary.main,
    }),
    divider: (theme: Theme) => ({
        border: `1px solid ${ isLight(theme)
            ? theme.palette.divider
            : theme.palette.info.light
        }`,
        marginTop: '15px',
    }),
    subtitleContainer: {
        marginTop: '20px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        justifyContent: 'space-between',
    },
    subtitle: (theme: Theme) => ({
        ...theme.typography.h5,
        lineHeight: '22px',
        color: theme.palette.text.primary,
    }),
    icon: {
        '& > svg': {
            width: '57px',
            height: '40px',
        },
    },
};

export interface DialogPaperContentProps {
    dialogDesktopProps: DialogDesktopProps;
    mainPaperPadding?: string;
}

export interface DesktopDialogTitleProps {
    title: string;
    step?: {
        stepsNumber: number,
        step: number,
    },
    subtitle?: string | JSX.Element;
    icon?: JSX.Element;
}

export const DesktopDialogTitle: FunctionComponent<
    DesktopDialogTitleProps
> = props => {
    const { title, subtitle, step, icon } = props;

    return <React.Fragment>
        { title && <React.Fragment>
            <Typography component="h4" sx={ styles.title }>
            { title }
            </Typography>
            { step && <StepComponent
                step={{ current: step.step, number: step.stepsNumber }}
            /> }
            <Divider sx={ styles.divider } />
        </React.Fragment> }

        <Box sx={ styles.subtitleContainer }>
            { subtitle &&
                typeof subtitle === 'string'
                    ? <Typography sx={ styles.subtitle } component="h5">
                        { subtitle }
                    </Typography>
                    : subtitle
            }
            { icon && <Box sx={ styles.icon }>{ props.icon }</Box> }
        </Box>
    </React.Fragment>;
};

const DialogPaper: FunctionComponent<
    DialogPaperContentProps
> = props => {
    const theme = useTheme<Theme>();

    return <DialogDesktopLayout
        dialogProps={ props.dialogDesktopProps.dialogProps }
        leftContent={ <Box
            sx={ styles.container(theme, props.mainPaperPadding) }
        >
            { props.dialogDesktopProps.leftContent }
        </Box> }
        rightContent={ props.dialogDesktopProps.rightContent }
    />;
};

export default DialogPaper;
