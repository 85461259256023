import React, { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import IsOfficeOpened from './IsOfficeOpened';
import { KnownBreakpoints } from '../../common/constants';
import { isLight } from '../../common/helpers';
import { useConfig } from '../../common/hooks/ConfigProvider';
import { officeHoursFormat } from '../../common/helpers/format';

const styles = {
    officeInfoRow: (withoutCenteredOfficeStatus?: boolean) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: withoutCenteredOfficeStatus ? 'start' : 'center',
    }),
    officeInfoText: (theme: Theme) => ({
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.fontSize,
        lineHeight: '16.41px',
        color: isLight(theme)
            ? theme.palette.secondary.dark
            : theme.palette.info.light
        ,
    }),
    officeInfoTextTablet: (theme: Theme) => ({
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.fontSize,
        lineHeight: '16.41px',
        color: theme.palette.text.primary,
    }),
};

interface OfficeInfoProps {
    styles?: { [key: string]: any };
    withoutCenteredOfficeStatus?: boolean;
}

export const OfficeInfoDesktop: FunctionComponent<OfficeInfoProps> = props => {
    const { consumerOperationsDetails : { officeHours } } = useConfig() as any;

    return <Box sx={{ ...styles.officeInfoRow, ...(props.styles || {}) }}>
        <Typography sx={ props.styles || styles.officeInfoText }>
            { officeHoursFormat(officeHours.start, officeHours.end) }
        </Typography>
        <IsOfficeOpened />
    </Box>;
};

const OfficeInfoLaptop: FunctionComponent<OfficeInfoProps> = props => {
    const { consumerOperationsDetails : { officeHours } } = useConfig() as any;

    return <Box sx={{ ...(props.styles || {}) }}>
        <Typography
            sx={ props.styles || styles.officeInfoText }
            textAlign="center"
        >
            { officeHoursFormat(officeHours.start, officeHours.end) }
        </Typography>
        <Box sx={ styles.officeInfoRow(props.withoutCenteredOfficeStatus) }>
            <IsOfficeOpened />
        </Box>
    </Box>;
};

const OfficeInfoTablet: FunctionComponent<OfficeInfoProps> = props => {
    const { consumerOperationsDetails : { officeHours } } = useConfig() as any;
    const { withoutCenteredOfficeStatus } = props;

    return <Box sx={{
        ...styles.officeInfoRow(withoutCenteredOfficeStatus),
        ...(props.styles || {}),
        marginBottom: '20px',
    }}>
        <Typography sx={ props.styles || styles.officeInfoTextTablet }>
            { officeHoursFormat(officeHours.start, officeHours.end) }
        </Typography>
        <IsOfficeOpened />
    </Box>;
};

const OfficeInfo: FunctionComponent<OfficeInfoProps> = props => {
    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(KnownBreakpoints.laptop),
    );
    const isLaptop = useMediaQuery(
        theme.breakpoints.between(
            KnownBreakpoints.tablet,
            KnownBreakpoints.laptop,
        ),
    );

    return <React.Fragment>
        { isDesktop
            ? <OfficeInfoDesktop { ...props } />
            : isLaptop
                ? <OfficeInfoLaptop { ...props } />
                : <OfficeInfoTablet { ...props } />
        }
    </React.Fragment>;
};

export default OfficeInfo;
