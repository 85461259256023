import React, { FunctionComponent } from 'react';
import { useTheme } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../../common/constants';
import Button, { ButtonProps } from '@mui/material/Button';
import { isLight } from '../../common/helpers';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HeadphoneKidIcon from '../../assets/SvgIcons/HeadphoneKidIcon';
import { useConfig } from '../../common/hooks/ConfigProvider';

const styles = {
    desktopPaper: (theme: Theme, isError: boolean) => ({
        width: '1130px',
        height: '720px',
        backgroundColor: isError
            ? theme.palette.error.dark
            : isLight(theme)
                ? theme.palette.common.white
                : theme.palette.secondary.main,
        backgroundImage: 'none',
        padding: '0 50px',
        '& *': isError ? {
            color: `${theme.palette.common.white} !important`,
        } : {},
    }),
    mobilePaper: (
        theme: Theme,
        withAdditionalInfo?: string,
        isError?: boolean,
    ) => ({
        backgroundColor: isError
            ? theme.palette.error.dark
            : withAdditionalInfo
                ? isLight(theme)
                    ? theme.palette.common.white
                    : theme.palette.secondary.dark
                : isLight(theme)
                    ? theme.palette.primary.dark
                    : theme.palette.primary.main
        ,
        backgroundImage: 'none',
        '& *': isError ? {
            color: `${theme.palette.common.white} !important`,
        } : {},
    }),
    desktopContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        verticalAlign: 'middle',
        height: '100%',
    },
    mobileContent: (theme: Theme, withAdditionalInfo?: string) => ({
        padding: '86px 20px 0 20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: withAdditionalInfo ? '650px' : 'unset',
        backgroundColor: withAdditionalInfo
            ? isLight(theme)
                ? theme.palette.primary.dark
                : theme.palette.primary.main
            : 'unset'
        ,
        paddingBottom: '30px',
    }),
    desktopTitle: (theme: Theme) => ({
        ...theme.typography.h3,
        lineHeight: '38px',
        color: theme.palette.text.primary,
        marginTop: '40px',
    }),
    mobileTitle: (theme: Theme) => ({
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
        color: theme.palette.common.white,
    }),
    iconContainer: (theme: Theme, isError: boolean) => ({
        marginTop: '40px',
        width: '130px',
        height: '130px',
        backgroundColor: isError
            ? theme.palette.error.light
            : theme.palette.primary.light,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    desktopSubtitle: (theme: Theme) => ({
        ...theme.typography.body1,
        lineHeight: '24px',
        color: theme.palette.text.primary,
        marginTop: '10px',
    }),
    mobileSubTitle: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '32px',
        color: theme.palette.common.white,
        marginTop: '30px',
    }),
    desktopButton : {
        marginTop: '40px',
        minWidth: '325px',
    },
    mobileButton : {
        marginTop: '30px',
        width: '100%',
    },
    additionalInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: '0 50px',
        marginTop: '30px',
        marginBottom: '30px',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
    },
    additionalInfoText: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '22px',
        color: theme.palette.text.primary,
    }),
    additionalInfoIcon: {
        fontSize: '36px',
    },
};

interface DesktopDialogAlertProps {
    icon: React.JSX.Element;
    title: string;
    buttonTitle: string;
    buttonProps: ButtonProps;
    dialogProps: DialogProps;
    subtitle?: string;
    withAdditionalInfo?: string;
    withCallButton?: boolean;
    variant?: 'error' | 'success';
    children?: JSX.Element;
}

const DesktopDialogAlert: FunctionComponent<
    DesktopDialogAlertProps
> = props => {
    const {
        icon,
        title,
        subtitle,
        buttonTitle,
        buttonProps,
        dialogProps,
        withAdditionalInfo,
        withCallButton,
        children,
    } = props;
    const variant = props.variant ?? 'success';
    const theme = useTheme<Theme>();
    const { consumerOperationsDetails: { phoneNumber }} = useConfig() as any;

    return <Dialog
        { ...dialogProps }
        fullScreen={ false }
        fullWidth={ true }
        PaperProps={{ sx: styles.desktopPaper(theme, variant === 'error') }}
    >
        <Box sx={ styles.desktopContent }>
            <Box sx={ styles.iconContainer(theme, variant === 'error') }>
                { icon }
            </Box>
            <Typography
                component="h3"
                sx={ styles.desktopTitle }
                textAlign={ 'center' }
            >
                { title }
            </Typography>
            { subtitle && <Typography
                sx={ styles.desktopSubtitle }
                textAlign="center"
            >
                { subtitle }
            </Typography> }
            { withAdditionalInfo && <Typography sx={ styles.desktopSubtitle }>
                { withAdditionalInfo }
            </Typography> }
            { withCallButton && <Button
                onClick={ () => location.href = `tel:${ phoneNumber }` }
                variant="contained"
                sx={ styles.desktopButton }
                color={ isLight(theme) || variant === 'error'
                    ? 'secondary'
                    : undefined
                }
                daata-testid="btn-dialog-callto"
            >
                Call: { phoneNumber }
            </Button> }
            <Button
                data-testid="btn-dialog-action"
                { ...buttonProps }
                variant="contained"
                color={ isLight(theme) || variant === 'error'
                    ? 'secondary'
                    : undefined
                }
                sx={ styles.desktopButton }
            >
                { buttonTitle }
            </Button>
            { children }
        </Box>
    </Dialog>;
};

const MobileDialogAlert: FunctionComponent<
    DesktopDialogAlertProps
> = props => {
    const {
        icon,
        title,
        subtitle,
        buttonTitle,
        buttonProps,
        dialogProps,
        withAdditionalInfo,
        withCallButton,
        children,
    } = props;
    const { consumerOperationsDetails: { phoneNumber }} = useConfig() as any;
    const variant = props.variant ?? 'success';
    const theme = useTheme<Theme>();

    return <Dialog
        { ...dialogProps }
        fullScreen={ true }
        fullWidth={ true }
        PaperProps={{
            sx: styles.mobilePaper(
                theme,
                withAdditionalInfo,
                variant === 'error',
            ),
        }}
    >
        <Box sx={ styles.mobileContent(theme, withAdditionalInfo) }>
            <Typography sx={ styles.mobileTitle } textAlign="center">
                { title }
            </Typography>
            <Box sx={ styles.iconContainer(theme, variant === 'error') }>
                { icon }
            </Box>
            { subtitle && <Typography
                sx={ styles.mobileSubTitle }
                textAlign="center"
            >
                { subtitle }
            </Typography> }
            { withCallButton && <Button
                onClick={ () => location.href = `tel:${ phoneNumber }` }
                variant="contained"
                sx={ styles.mobileButton }
                color="secondary"
                daata-testid="btn-dialog-callto"
            >
                Call: { phoneNumber }
            </Button> }
            <Button
                data-testid="btn-dialog-action"
                { ...buttonProps }
                variant="contained"
                color="secondary"
                sx={ styles.mobileButton }
            >
                { buttonTitle }
            </Button>
            { children }
        </Box>
        { withAdditionalInfo && <Box sx={ styles.additionalInfoContainer }>
            <Typography sx={ styles.additionalInfoText }>
                { withAdditionalInfo }
            </Typography>
            <HeadphoneKidIcon sx={ styles.additionalInfoIcon } />
        </Box> }
    </Dialog>;
};

const DialogAlert: FunctionComponent<DesktopDialogAlertProps> = props => {
    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(KnownBreakpoints.tablet),
    );

    return isDesktop
        ? <DesktopDialogAlert { ...props } />
        : <MobileDialogAlert { ...props } />
    ;
};

export default DialogAlert;
